import { Refresh } from "@mui/icons-material";
import { Button } from "@mui/material";
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid";
import { useSetRecoilState } from "recoil";
import { refreshGridState } from "../../atoms";

export default function GridToolbar() {
  const setRefresh = useSetRecoilState(refreshGridState);

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <Button color="primary" startIcon={<Refresh />} size="small" onClick={() => setRefresh(true)}>
        Refresh
      </Button>
    </GridToolbarContainer>
  );
}
