import { RacwaDetailedDialog } from "@racwa/react-components";
import React from "react";
import CancelButton from "../CancelButton";
import ContinueButton from "../ContinueButton";
import { StyledDialogActions } from "./styles";

export interface DialogProps {
  showDialog: boolean;
  dialogName: string;
  dialogTitle: string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  onCancelClick?: () => void;
  onContinueClick?: () => void;
  children?: React.ReactNode;
}

export const Dialog: React.FC<DialogProps> = ({
  showDialog,
  dialogName,
  dialogTitle,
  maxWidth,
  onCancelClick,
  onContinueClick,
  children,
}) => {
  const dialog = (
    <RacwaDetailedDialog
      id={dialogName}
      title={dialogTitle}
      titleId={`${dialogName}-title`}
      open={showDialog}
      maxWidth={maxWidth}
      onClickClose={onCancelClick}
      customChildren={[
        {
          dialogueContent: false,
          node: children,
        },
        {
          dialogueContent: false,
          node: (
            <StyledDialogActions>
              <CancelButton onClick={onCancelClick}></CancelButton>
              {onContinueClick && <ContinueButton onClick={onContinueClick}></ContinueButton>}
            </StyledDialogActions>
          ),
        },
      ]}
    />
  );

  return showDialog ? dialog : null;
};

export default Dialog;
