import { Grid, Link, styled, Tab, Tabs } from "@mui/material";
import { RacwaResponsiveHeader, RacwaThemeProvider } from "@racwa/react-components";
import { useEffect, useState } from "react";
import { RecoilRoot } from "recoil";
import "./App.css";
import { toastNotificationState } from "./atoms";
import Backdrop from "./shared/Backdrop";
import ClearEnvironmentButton from "./shared/ClearEnvironmentButton";
import ClearEnvironmentDialog from "./shared/ClearEnvironmentDialog";
import ConnectWithRecoilValue from "./shared/ConnectWithRecoilValue";
import CreateOrganisationButton from "./shared/CreateOrganisationButton";
import CreateOrganisationDialog from "./shared/CreateOrganisationDialog";
import CreatePersonButton from "./shared/CreatePersonButton";
import CreatePersonDialog from "./shared/CreatePersonDialog";
import SelectEnvironment from "./shared/EnvironmentSelector";
import LinkOrganisationButton from "./shared/LinkOrganisationButton";
import LinkOrganisationDialog from "./shared/LinkOrganisationDialog";
import LinkPersonButton from "./shared/LinkPersonButton";
import LinkPersonDialog from "./shared/LinkPersonDialog";
import OrganisationGrid from "./shared/OrganisationGrid";
import PersonGrid from "./shared/PersonGrid";
import SetIsAlive from "./shared/SetIsAlive";
import SetProductHoldingResponse from "./shared/SetProductHoldingResponse";
import SetStatusOverride from "./shared/SetStatusOverride";
import environmentSettings from "./shared/Settings/environmentSettings";
import ToastNotification from "./shared/ToastNotification";

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
}));

export const App: React.FC = () => {
  const shieldEnvironment = environmentSettings().current;
  const { ...environmentProps } = environmentSettings();

  const [showLinkPersonDialog, setShowLinkPersonDialog] = useState(false);
  useEffect(() => {
    setShowLinkPersonDialog(showLinkPersonDialog);
  }, [showLinkPersonDialog]);

  const [showCreatePersonDialog, setShowCreatePersonDialog] = useState(false);
  useEffect(() => {
    setShowCreatePersonDialog(showCreatePersonDialog);
  }, [showCreatePersonDialog]);

  const [showCreateOrganisationDialog, setShowCreateOrganisationDialog] = useState(false);
  useEffect(() => {
    setShowCreateOrganisationDialog(showCreateOrganisationDialog);
  }, [showCreateOrganisationDialog]);

  const [showLinkOrganisationDialog, setShowLinkOrganisationDialog] = useState(false);
  useEffect(() => {
    setShowLinkOrganisationDialog(showLinkOrganisationDialog);
  }, [showLinkOrganisationDialog]);

  const [showClearEnvironmentDialog, setShowClearEnvironmentDialog] = useState(false);
  useEffect(() => {
    setShowClearEnvironmentDialog(showClearEnvironmentDialog);
  }, [showClearEnvironmentDialog]);

  const [tabsValue, setTabsValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabsValue(newValue);
  };

  return (
    <RacwaThemeProvider>
      <RecoilRoot>
        <Backdrop />
        <ConnectWithRecoilValue component={ToastNotification} recoilValue={toastNotificationState} />
        <ToastNotification message={null} />
        <RacwaResponsiveHeader breadcrumbs={[<Link href="#">Member Central Mock</Link>]}>
          <SelectEnvironment settings={environmentProps} />
          <ClearEnvironmentButton
            isDisabled={!shieldEnvironment()}
            onClick={() => setShowClearEnvironmentDialog(true)}
          />
        </RacwaResponsiveHeader>

        <StyledGrid container wrap="nowrap" direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={3} columnSpacing={"none"}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={6}>
                <LinkPersonButton onClick={() => setShowLinkPersonDialog(true)} />
              </Grid>
              <Grid item xs={6}>
                <LinkOrganisationButton onClick={() => setShowLinkOrganisationDialog(true)} />
              </Grid>
              <Grid item xs={6}>
                <CreatePersonButton onClick={() => setShowCreatePersonDialog(true)} />
              </Grid>
              <Grid item xs={6}>
                <CreateOrganisationButton onClick={() => setShowCreateOrganisationDialog(true)} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <Grid container direction="row" justifyContent="flex-end" spacing={1}>
              <Grid item>
                <SetStatusOverride />
              </Grid>
              <Grid item>
                <SetProductHoldingResponse />
              </Grid>
              <Grid item>
                <SetIsAlive />
              </Grid>
            </Grid>
          </Grid>
        </StyledGrid>

        <Tabs value={tabsValue} onChange={handleChange}>
          <Tab label="Contacts"></Tab>
          <Tab label="Organisations"></Tab>
        </Tabs>
        <PersonGrid value={tabsValue} index={0} />
        <OrganisationGrid value={tabsValue} index={1} />

        <ClearEnvironmentDialog
          showDialog={showClearEnvironmentDialog}
          onCancelClick={() => setShowClearEnvironmentDialog(false)}
        />

        <LinkPersonDialog showDialog={showLinkPersonDialog} onCancelClick={() => setShowLinkPersonDialog(false)} />
        <CreatePersonDialog
          showDialog={showCreatePersonDialog}
          onCancelClick={() => setShowCreatePersonDialog(false)}
        />
        <LinkOrganisationDialog
          showDialog={showLinkOrganisationDialog}
          onCancelClick={() => setShowLinkOrganisationDialog(false)}
        />
        <CreateOrganisationDialog
          showDialog={showCreateOrganisationDialog}
          onCancelClick={() => setShowCreateOrganisationDialog(false)}
        />
      </RecoilRoot>
    </RacwaThemeProvider>
  );
};

export default App;
