import { GridColDef } from "@mui/x-data-grid";
import ReadOnlyColumn from "../PersonGrid/ReadOnlyColumn";
import { renderBlankHeader } from "../UpdateButton/updateButtonFunctions";

export const contactIdField = "id";
export const hasProductHoldingsField = "hasProductHoldings";

export const organisationGridColumns = (): GridColDef[] => {
  return [
    {
      field: "Actions",
      hideSortIcons: true,
      sortable: false,
      renderHeader: renderBlankHeader,
    },
    ReadOnlyColumn("timestamp", "Timestamp", 0.75, true),
    ReadOnlyColumn("contactId", "Shield Contact Id", 0.5, false),
    ReadOnlyColumn("crmId", "Mock Organisation ID", 1, true),
    ReadOnlyColumn("organisationName", "Organisation Name", 1, false),
    ReadOnlyColumn("workPhoneNumber", "Work Number", 0.5, false),
    ReadOnlyColumn("mobilePhoneNumber", "Mobile Number", 0.5, false),
    ReadOnlyColumn("workEmail", "Work Email", 1, false),
    ReadOnlyColumn("matchStatusCode", "Match Status", 1, false),
  ];
};
