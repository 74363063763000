import { GridValueFormatterParams } from "@mui/x-data-grid";

export const formatGridDate = (params: GridValueFormatterParams) => {
  const cellValue = params.value;

  if (cellValue) {
    const cellString = cellValue.toString();
    const dateOfBirth = new Date(cellString);

    return dateOfBirth.toLocaleDateString("en-AU");
  } else {
    console.warn(
      `Person with shield contact id '%s' has an invalid date of birth which cannot be displayed. 
       Please resolve manually or delete the offending person record. 
       More info: https://rac-wa.atlassian.net/wiki/spaces/ISP/pages/2519662656/MC+Mock+Troubleshooting#%E2%80%9CAn-error-occurred%E2%80%9D-when-loading-grid-for-a-specific-environment`,
      params.id?.toString(),
    );
  }

  return cellValue;
};
