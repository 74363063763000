import { BodyCopy } from "@racwa/react-components";
import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { environmentState, refreshGridState, toastNotificationState } from "../../atoms";
import { Dialog, DialogProps } from "../Dialog";
import LinkPersonInput from "../LinkPersonInput";
import { REGEX_NUMERIC } from "../constants";
import { ApiException } from "../useApiClient/UtilityClientProxy.generated";
import { useGetPersonByShieldContactId } from "../utilityApi";

export interface LinkPersonDialogProps extends Omit<DialogProps, "dialogName" | "dialogTitle"> {}

const LinkPersonDialog: React.FC<LinkPersonDialogProps> = ({ showDialog, onCancelClick, ...props }) => {
  const environment = useRecoilValue(environmentState);
  const setGridRefresh = useSetRecoilState(refreshGridState);
  const setNotificationState = useSetRecoilState(toastNotificationState);
  const getPersonByShieldContactId = useGetPersonByShieldContactId();

  const [shieldContactId, setShieldContactId] = useState("");
  useEffect(() => {
    setShieldContactId(shieldContactId);
  }, [shieldContactId]);

  const [inputError, setInputError] = useState(false);
  useEffect(() => {
    setInputError(inputError);
  }, [inputError]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "" || REGEX_NUMERIC.test(event.target.value)) {
      setInputError(false);
    } else {
      setInputError(true);
    }
    setShieldContactId(event.target.value);
  };

  const closeDialog = () => {
    onCancelClick && onCancelClick();
    setShieldContactId("");
    setInputError(false);
  };

  const onContinueClick = async () => {
    if (
      shieldContactId === undefined ||
      shieldContactId === null ||
      shieldContactId === "" ||
      !REGEX_NUMERIC.test(shieldContactId)
    ) {
      setInputError(true);
    } else {
      try {
        closeDialog();
        await getPersonByShieldContactId(shieldContactId);
        setGridRefresh(true);
        setNotificationState({
          message: `Request to link person from ${environment} succeeded.`,
        });
      } catch (ex: any) {
        setNotificationState({
          message: `Request to link person from ${environment} failed.`,
          apiException: ex as ApiException,
        });
      }
    }
  };

  return (
    <Dialog
      showDialog={showDialog}
      dialogName="link-person-dialog"
      dialogTitle="Link Person"
      onCancelClick={closeDialog}
      onContinueClick={onContinueClick}
      {...props}
    >
      <BodyCopy gutterBottom>
        <strong>
          This will create a link between a Shield Contact from environment <b>{environment}</b> to a Person in the
          MemberCentralMock database.
        </strong>
      </BodyCopy>
      <LinkPersonInput {...props} inputValue={shieldContactId} isError={inputError} onChange={handleInputChange} />
    </Dialog>
  );
};

export default LinkPersonDialog;
