import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";

export interface CreateOrganisationButtonProps {
  onClick?: () => void;
}

const CreateOrganisationButton: React.FC<CreateOrganisationButtonProps> = ({ onClick }) => {
  const buttonName = "btn-create-organisation";
  return (
    <Button id={buttonName} title={buttonName} name={buttonName} startIcon={<Add />} size="small" onClick={onClick}>
      Create organisation
    </Button>
  );
};
export default CreateOrganisationButton;
