import { isAliveStatusCodeState } from "../../atoms";
import { SetDefaultResponse } from "../SetDefaultResponse";
import { retrieveIsAliveStatusCodes } from "../Settings/statusCodeSettings";
import { useSetIsAlive } from "../utilityApi";

export const SetIsAlive = () => {
  return (
    <SetDefaultResponse
      label="Environment Status"
      name="isAliveSelector"
      title="Environment isAlive status"
      statusCodeStateKey={isAliveStatusCodeState}
      setRemoteStatusCode={useSetIsAlive()}
      items={retrieveIsAliveStatusCodes()}
    />
  );
};

export default SetIsAlive;
