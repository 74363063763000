import { ApiResponse, OrganisationRequest, Person, PersonRequest } from "../useApiClient/UtilityClientProxy.generated";
import { useCreateOrganisation, useCreatePerson, useGetPersonByShieldContactId, useUpdatePerson } from "../utilityApi";

async function CreatePerson(newPerson: PersonRequest): Promise<ApiResponse<Person>> {
  const createPerson = useCreatePerson();
  return createPerson(newPerson);
}

async function CreateOrganisation(newOrganisation: OrganisationRequest): Promise<ApiResponse<Person>> {
  const createOrganisation = useCreateOrganisation();
  return createOrganisation(newOrganisation);
}

async function SearchByShieldContactId(shieldContactId: string): Promise<ApiResponse<Person>> {
  const search = useGetPersonByShieldContactId();
  return search(shieldContactId);
}

async function UpdatePerson(personId: string, newPerson: PersonRequest): Promise<ApiResponse<Person>> {
  const updatePerson = useUpdatePerson();
  return updatePerson(personId, newPerson);
}

export { CreatePerson, CreateOrganisation, SearchByShieldContactId, UpdatePerson };
