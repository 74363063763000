import { GridCellParams } from "@mui/x-data-grid";
import ShowProductHoldingsButton from "../ShowProductHoldingsButton";
import { contactIdField, hasProductHoldingsField } from "./memberGridColumns";

export const showProductHoldingsColumn = (
  cell: GridCellParams,
  showProductHoldings: (id: string) => void,
): JSX.Element => {
  const hasProductHoldings = cell.getValue(cell.id, hasProductHoldingsField) as boolean;

  if (!hasProductHoldings) {
    return <></>;
  }

  const contactId = cell.getValue(cell.id, contactIdField) as string;
  return <ShowProductHoldingsButton onClick={() => showProductHoldings(contactId)} />;
};
