import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { refreshGridState, toastNotificationState } from "../../atoms";
import { Dialog, DialogProps } from "../Dialog";
import { CreateOrganisation } from "../ManagePersonalDetails";
import { CONTACT_NUMBER_LENGTH, EMAIL_LENGTH } from "../constants";
import { Address, ApiException, OrganisationRequest } from "../useApiClient/UtilityClientProxy.generated";
import { getStatusCodeDescription } from "../utilities";

export interface CreateOrganisationDialogProps extends Omit<DialogProps, "dialogName" | "dialogTitle"> {}

const CreateOrganisationDialog: React.FC<CreateOrganisationDialogProps> = ({ showDialog, onCancelClick, ...props }) => {
  const setGridRefresh = useSetRecoilState(refreshGridState);
  const setNotificationState = useSetRecoilState(toastNotificationState);
  const [racId, setRacId] = useState("");
  const [crmId, setCrmId] = useState("");
  const [organisationName, setOrganisationName] = useState("");
  const [workPhone, setWorkPhone] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [workEmailAddress, setWorkEmailAddress] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [subbuildingNumber, setsubbuildingNumber] = useState("");
  const [poBox, setPoBox] = useState("");
  const [unitFlat, setunitFlat] = useState("");
  const [blockNumber, setBlockNumber] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [lotNumber, setLotNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [country, setCountry] = useState("Australia");
  const [state, setState] = useState("WA");
  const [suburb, setSuburb] = useState("");
  const [postcode, setPostcode] = useState("");
  const [shieldContactId, setShieldContactId] = useState("");
  const [completed, setCompleted] = useState(false);

  const getFailedOrganisationNotificationMessage = (statusCode: number) => {
    const statusCodeDescription = getStatusCodeDescription(statusCode);
    let notificationMessage = `Request to create organisation failed. (${statusCode} ${statusCodeDescription})`;

    if (statusCode === 400) {
      notificationMessage += "\nCheck data entered.";
    }

    return notificationMessage;
  };

  useEffect(() => {
    setShieldContactId(shieldContactId);
  }, [shieldContactId]);

  const [inputError, setInputError] = useState(false);
  useEffect(() => {
    setInputError(inputError);
  }, [inputError]);

  const closeDialog = () => {
    setCompleted(false);
    onCancelClick && onCancelClick();
  };

  function ClearForm() {
    setRacId("");
    setCrmId("");
    setOrganisationName("");
    setWorkPhone("");
    setMobilePhone("");
    setWorkEmailAddress("");
    setBuildingName("");
    setsubbuildingNumber("");
    setPoBox("");
    setunitFlat("");
    setBlockNumber("");
    setHouseNumber("");
    setLotNumber("");
    setStreetName("");
    setCountry("Australia");
    setState("WA");
    setSuburb("");
    setPostcode("");
  }

  const onContinueClick = async () => {
    if (completed) {
      setCompleted(false);
      ClearForm();
      closeDialog();
      setGridRefresh(true);
    } else {
      const organisationAddress: Address = {
        buildingName: buildingName,
        subBuildingNumber: subbuildingNumber,
        unitNumber: unitFlat,
        blockNumber: blockNumber,
        houseNumber: houseNumber,
        lotNumber: lotNumber,
        streetName: streetName,
        poBox: poBox,
        suburb: suburb,
        state: state,
        postcode: postcode,
        country: country,
        qasValidated: false,
      };

      const newOrganisation: OrganisationRequest = {
        organisationName,
        workPhoneNumber: workPhone,
        mobilePhoneNumber: mobilePhone,
        workEmail: workEmailAddress,
        postalAddress: organisationAddress,
      };

      try {
        const organisation = await CreateOrganisation(newOrganisation);
        setRacId(organisation.result.racId!);
        setCrmId(organisation.result.personId!);

        setNotificationState({
          message: `Request to create organisation succeeded. Click on Continue to return to main page `,
        });
        setCompleted(true);
      } catch (ex: any) {
        setNotificationState({
          message: getFailedOrganisationNotificationMessage(ex.status),
          apiException: ex as ApiException,
        });

        if (ex.status === 299) {
          closeDialog();
          setGridRefresh(true);
        } else if (ex.status !== 400) {
          closeDialog();
        }
      }
    }
  };

  return (
    <Dialog
      showDialog={showDialog}
      dialogName="create-organisation-dialog"
      dialogTitle="Create Organisation"
      onCancelClick={closeDialog}
      onContinueClick={onContinueClick}
      {...props}
    >
      <table>
        <tr>
          <td>Rac Id</td>
          <td>
            <label>{racId}</label>
          </td>
        </tr>
        <tr>
          <td>Organisation Id / Crm Id</td>
          <td>
            <label>{crmId}</label>
          </td>
        </tr>

        <tr>
          {" "}
          <td>Organisation Name </td>
          <td>
            <input
              type="text"
              name={organisationName}
              maxLength={55}
              size={30}
              onChange={(e) => setOrganisationName(e.target.value)}
            ></input>
          </td>
        </tr>

        <tr>
          {" "}
          <td>Work Phone </td>
          <td>
            <input
              type="text"
              name={workPhone}
              maxLength={CONTACT_NUMBER_LENGTH}
              size={CONTACT_NUMBER_LENGTH}
              onChange={(e) => setWorkPhone(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Mobile Phone </td>
          <td>
            <input
              type="text"
              name={mobilePhone}
              maxLength={CONTACT_NUMBER_LENGTH}
              size={CONTACT_NUMBER_LENGTH}
              onChange={(e) => setMobilePhone(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Work Email Address </td>
          <td>
            <input
              type="text"
              name={workEmailAddress}
              maxLength={EMAIL_LENGTH}
              size={30}
              onChange={(e) => setWorkEmailAddress(e.target.value)}
            ></input>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          {" "}
          <td>Building Name</td>
          <td>
            <input
              type="text"
              name={buildingName}
              maxLength={50}
              onChange={(e) => setBuildingName(e.target.value)}
            ></input>
          </td>
          <td>Subbuilding Number </td>
          <td>
            <input type="text" name={subbuildingNumber} onChange={(e) => setsubbuildingNumber(e.target.value)}></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>PO Box </td>
          <td>
            <input type="text" name={poBox} maxLength={20} onChange={(e) => setPoBox(e.target.value)}></input>
          </td>
          <td>Unit/Flat</td>
          <td>
            <input type="text" name={unitFlat} maxLength={9} onChange={(e) => setunitFlat(e.target.value)}></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Block Number </td>
          <td>
            <input
              type="text"
              name={blockNumber}
              maxLength={10}
              onChange={(e) => setBlockNumber(e.target.value)}
            ></input>
          </td>
          <td>Number </td>
          <td>
            <input
              type="text"
              name={houseNumber}
              maxLength={14}
              onChange={(e) => setHouseNumber(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Lot Number </td>
          <td>
            <input type="text" name={lotNumber} maxLength={14} onChange={(e) => setLotNumber(e.target.value)}></input>
          </td>
          <td>Street Name </td>
          <td>
            <input
              type="text"
              name={streetName}
              maxLength={255}
              onChange={(e) => setStreetName(e.target.value)}
            ></input>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <td width={100}>Country</td>
          <td>
            <input
              type="text"
              name={country}
              value={country}
              maxLength={100}
              size={70}
              onChange={(e) => setCountry(e.target.value)}
            ></input>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          {" "}
          <td width={100}>State </td>
          <td>
            <input
              type="text"
              name={state}
              value={state}
              maxLength={10}
              size={10}
              onChange={(e) => setState(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Suburb </td>
          <td>
            <input type="text" name={suburb} onChange={(e) => setSuburb(e.target.value)}></input>
          </td>
          <td>Postcode </td>
          <td>
            <input type="text" maxLength={4} name={postcode} onChange={(e) => setPostcode(e.target.value)}></input>
          </td>
        </tr>
      </table>
    </Dialog>
  );
};

export default CreateOrganisationDialog;
