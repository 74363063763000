import { FormControl } from "@mui/material";
import { RacwaTextInput, RacwaTextInputProps } from "@racwa/react-components";
import React from "react";

export interface LinkPersonInputProps extends RacwaTextInputProps {
  inputValue: string;
  isError: boolean;
}

const LinkPersonInput: React.FC<LinkPersonInputProps> = ({ inputValue, isError, onChange, ...props }) => {
  const title = "link-person-contact-id";
  return (
    <FormControl>
      <RacwaTextInput
        {...props}
        id={title}
        title={title}
        placeholder="Shield Contact ID"
        type="text"
        error={isError}
        required={true}
        helperText={isError ? "Please provide a numeric value for Shield Contact ID" : undefined}
        value={inputValue}
        onChange={(e) => onChange && onChange(e)}
      />
    </FormControl>
  );
};

export default LinkPersonInput;
