import React from "react";
import { StyledButton } from "../Dialog/styles";

const button = "cancel";
const buttonName = `btn-${button}`;

export interface CancelButtonProps {
  onClick?: () => void;
}

export const CancelButton: React.FC<CancelButtonProps> = ({ onClick }) => {
  return (
    <StyledButton id={buttonName} name={buttonName} title={buttonName} aria-label={button} onClick={onClick}>
      Cancel
    </StyledButton>
  );
};

export default CancelButton;
