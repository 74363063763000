import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";

export interface CreatePersonButtonProps {
  onClick?: () => void;
}

const CreatePersonButton: React.FC<CreatePersonButtonProps> = ({ onClick }) => {
  const buttonName = "btn-create-person";
  return (
    <Button id={buttonName} title={buttonName} name={buttonName} startIcon={<Add />} size="small" onClick={onClick}>
      Create person
    </Button>
  );
};
export default CreatePersonButton;
