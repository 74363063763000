import { Tooltip } from "@mui/material";
import { GridCellParams } from "@mui/x-data-grid";

const renderCellWithTooltip = (params: any) => {
  return (
    <Tooltip title={params.value}>
      <span>{params.value}</span>
    </Tooltip>
  );
};

export const ReadOnlyColumn = (field: string, headerName: string, flex: number, hide: boolean) => {
  return {
    field,
    headerName,
    description: headerName,
    flex,
    hide,
    hideSortIcons: true,
    renderCell: (cellParams: GridCellParams) => renderCellWithTooltip(cellParams),
  };
};

export default ReadOnlyColumn;
