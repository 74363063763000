import { GridRowModel } from "@mui/x-data-grid";
import { atom } from "recoil";
import { ToastNotificationProps } from "../shared/ToastNotification";
import { SESSION_KEY_PREFIX } from "../shared/constants";

const environmentKey = `${SESSION_KEY_PREFIX}shieldEnvironment`;
const toastKey = `${SESSION_KEY_PREFIX}ToastNotificationData`;
const refreshGridKey = `${SESSION_KEY_PREFIX}refreshGridState`;
const backdropCounterKey = `${SESSION_KEY_PREFIX}backdropCounter`;
const isAliveStatusCodeKey = `${SESSION_KEY_PREFIX}isAliveStatusCode`;
const statusOverrideKey = `${SESSION_KEY_PREFIX}statusOverride`;
const productHoldingStatusCodeKey = `${SESSION_KEY_PREFIX}productHoldingStatusCode`;
const dataGridRowsKey = `${SESSION_KEY_PREFIX}dataGridRows`;
const dataGridOrganisationRowsKey = `${SESSION_KEY_PREFIX}dataGridOrganisationRows`;

export const toastNotificationState = atom<ToastNotificationProps>({
  key: toastKey,
  default: { message: null, apiException: null },
});

export const refreshGridState = atom<boolean>({
  key: refreshGridKey,
  default: false,
});

export const environmentState = atom<string>({
  key: environmentKey,
  default: "",
});

export const backdropCounterState = atom<number>({
  key: backdropCounterKey,
  default: 0,
});

export const isAliveStatusCodeState = atom<number>({
  key: isAliveStatusCodeKey,
  default: 0,
});

export const statusOverrideState = atom<number>({
  key: statusOverrideKey,
  default: 0,
});

export const productHoldingStatusCodeState = atom<number>({
  key: productHoldingStatusCodeKey,
  default: 0,
});

export const dataGridRowsState = atom<GridRowModel[]>({
  key: dataGridRowsKey,
  default: [],
});

export const dataGridOrganisationRowsState = atom<GridRowModel[]>({
  key: dataGridOrganisationRowsKey,
  default: [],
});
