import { FileCopyOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";

export interface CopyToClipboardButtonProps {
  getText: () => string;
}

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({ getText }) => {
  const buttonName = "btn-copy-to-clipboard";
  const [isTooltipOpen, showTooltip] = React.useState(false);

  function copy() {
    const text = getText();
    navigator.clipboard.writeText(text);
    showTooltip(true);
  }

  return (
    <Tooltip title="Copied" open={isTooltipOpen} leaveDelay={800} onClose={() => showTooltip(false)}>
      <IconButton name={buttonName} title={buttonName} size="small" style={{ marginRight: "1rem" }} onClick={copy}>
        <FileCopyOutlined />
      </IconButton>
    </Tooltip>
  );
};

export default CopyToClipboardButton;
