import React from "react";
import { StyledButton } from "../Dialog/styles";

const button = "continue";
const buttonName = `btn-${button}`;

export interface ContinueButtonProps {
  onClick?: () => void;
}

export const ContinueButton: React.FC<ContinueButtonProps> = ({ onClick }) => {
  return (
    <StyledButton
      id={buttonName}
      name={buttonName}
      title={buttonName}
      aria-label={button}
      color="primary"
      onClick={onClick}
    >
      Continue
    </StyledButton>
  );
};

export default ContinueButton;
