import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import GridToolbar from "../PersonGrid/gridToolbar";
import BindGrid from "./BindGrid";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

export const OrganisationGrid = (props: TabPanelProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const response = BindGrid({
    setIsLoading,
  });

  return (
    <div>
      <DataGrid
        rows={response.data.rows}
        columns={response.data.columns}
        components={{
          Toolbar: GridToolbar,
        }}
        loading={isLoading}
        style={{ height: "85vh" }}
      ></DataGrid>
    </div>
  );
};

export default OrganisationGrid;
