import { productHoldingStatusCodeState } from "../../atoms";
import { SetDefaultResponse } from "../SetDefaultResponse";
import { retrieveProductHoldingStatusCodes } from "../Settings/statusCodeSettings";
import { useSetProductHoldingResponse } from "../utilityApi";

export const SetProductHoldingResponse = () => {
  return (
    <SetDefaultResponse
      title="ProductHolding response status"
      name="productHoldingResponseSelector"
      label="RSA Bundling Response"
      statusCodeStateKey={productHoldingStatusCodeState}
      setRemoteStatusCode={useSetProductHoldingResponse()}
      items={retrieveProductHoldingStatusCodes()}
    />
  );
};

export default SetProductHoldingResponse;
