import { GridColDef, GridRowModel } from "@mui/x-data-grid";
import { GridDemoData } from "@mui/x-data-grid-generator";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { dataGridRowsState, environmentState, refreshGridState, toastNotificationState } from "../../atoms";
import { PopulateGrid } from "./PopulateGrid";
import { memberGridColumns } from "./memberGridColumns";

interface BindGridProps {
  setIsLoading: (state: boolean) => void;
  selectPerson: (value: string) => {};
  showProductHoldings: (id: string) => void;
}
export interface BindGridResponse {
  data: GridDemoData;
}

export function BindGrid(props: BindGridProps): BindGridResponse {
  const { setIsLoading, selectPerson, showProductHoldings } = { ...props };

  const [notification, setNotificationState] = useRecoilState(toastNotificationState);

  const [refresh, setRefresh] = useRecoilState(refreshGridState);
  const environment = useRecoilValue(environmentState);
  const [rows, setRows] = useRecoilState<GridRowModel[]>(dataGridRowsState);
  const [localRefresh, setLocalRefresh] = useState<boolean>(refresh);
  const [localEnv, setLocalEnv] = useState<string>(environment);

  const [columns] = useState<GridColDef[]>(memberGridColumns(selectPerson, showProductHoldings));

  useEffect(() => {
    if (refresh !== localRefresh || environment !== localEnv) {
      PopulateGrid(setRows, setIsLoading, setNotificationState);
      setLocalRefresh(false);
      setRefresh(false);
      setLocalEnv(environment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, environment, notification]);

  return { data: { rows, columns } };
}

export default BindGrid;
