import { styled, TableRow, tableRowClasses } from "@mui/material";

export const StyledTableRow = styled(TableRow)({
  [`.&${tableRowClasses.root}`]: {
    borderBottom: "unset",
    whiteSpace: "nowrap",
    cursor: "pointer",
  },
});

export default StyledTableRow;
