import { Close } from "@mui/icons-material";
import { IconButton, Slide, SlideProps, SnackbarContent } from "@mui/material";
import { colors } from "@racwa/styles";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { toastNotificationState } from "../../atoms";
import { AZURE_AD_TOKEN_EXPIRED_MESSAGE } from "../constants";
import { ApiException } from "../useApiClient/UtilityClientProxy.generated";
import { useIsForbiddenResponse } from "../utilityApi";
import { StyledSnackBar } from "./styles";

const { REACT_APP_URL } = process.env;
const autoHideDurationInMilliseconds = 10000;

const SlideTransition = (props: SlideProps) => {
  return <Slide {...props} direction="down" />;
};

export interface ToastNotificationProps {
  message: string | null;
  apiException?: ApiException | null;
}

const ToastNotification: React.FC<ToastNotificationProps> = (props) => {
  const notificationName = "toastNotification";
  const [notificationState, setNotificationState] = useRecoilState(toastNotificationState);
  const isForbiddenResponse = useIsForbiddenResponse(props.apiException?.status);

  useEffect(() => {
    setNotificationState(notificationState);
  }, [notificationState, setNotificationState]);

  const closeNotification = () => {
    setNotificationState({ message: null, apiException: null });
  };

  const exceptionMessage = isForbiddenResponse ? (
    <>
      {AZURE_AD_TOKEN_EXPIRED_MESSAGE} <br />
      <div>
        <a
          href={REACT_APP_URL}
          title="Link to reauthenticate in a new browser tab"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#000000" }}
        >
          Please click here to reauthenticate in a new browser tab
        </a>
      </div>
    </>
  ) : (
    <>
      {props.message} <br />
      {props.apiException?.message} (StatusCode: {props.apiException?.status}) <br />
      {props.apiException?.response}
    </>
  );

  const toastContent = (
    <SnackbarContent
      message={props.apiException ? exceptionMessage : props.message}
      action={
        <IconButton size="small" aria-label="close" onClick={closeNotification}>
          <Close fontSize="small" />
        </IconButton>
      }
      style={{
        background: props.apiException ? colors.brandWarning : colors.brandSuccess,
      }}
    />
  );

  return (
    <StyledSnackBar
      id={notificationName}
      title="Notification"
      open={props.message !== null && props.message !== undefined && props.message !== ""}
      autoHideDuration={isForbiddenResponse ? null : autoHideDurationInMilliseconds}
      disableWindowBlurListener={true}
      onClose={(_event: any, reason: string) => {
        if (reason !== "clickaway") {
          closeNotification();
        }
      }}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      children={toastContent}
      TransitionComponent={SlideTransition}
    />
  );
};

export default ToastNotification;
