import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { GridCellParams, GridRowModel } from "@mui/x-data-grid";
import { useRecoilState, useSetRecoilState } from "recoil";
import { dataGridRowsState, toastNotificationState } from "../../atoms";
import { backdropState } from "../../selectors";
import { ApiException, ApiResponse } from "../useApiClient/UtilityClientProxy.generated";
import { useSetMatchStatus } from "../utilityApi";
import { contactIdField } from "./memberGridColumns";

interface SelectMatchStatusPresentationProps {
  cell: GridCellParams;
  onChange: (contactId: string, statusCode: string) => Promise<ApiResponse<void>>;
}

export const SelectMatchStatusPresentation: React.FC<SelectMatchStatusPresentationProps> = ({
  cell,
  onChange,
}): JSX.Element => {
  const setNotificationState = useSetRecoilState(toastNotificationState);
  const setBackdrop = useSetRecoilState(backdropState);
  const [rows, setRows] = useRecoilState<GridRowModel[]>(dataGridRowsState);

  const contactId = cell.getValue(cell.id, contactIdField) as string;

  const updateRowsWithNewMatchStatus = (newMatchStatus: string) => {
    const newRows = rows.map((row) => {
      const newRow = {
        ...row,
      } as GridRowModel;

      if (newRow.id === contactId) {
        newRow.matchStatusCode = parseInt(newMatchStatus);
      }

      return newRow;
    });

    setRows(newRows);
  };

  const onSelectStatus = async (event: SelectChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    try {
      setBackdrop(true);
      const newMatchStatus = event.target.value as string;
      await onChange(contactId, newMatchStatus);
      updateRowsWithNewMatchStatus(newMatchStatus);

      setNotificationState({
        message: `Match status updated for contact #${contactId}`,
      });
    } catch (ex: any) {
      setNotificationState({
        message: `Unable to update match status for contact #${contactId}`,
        apiException: ex as ApiException,
      });
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <Select data-testid={`matchStatus${contactId}`} value={cell.value} onChange={onSelectStatus} fullWidth>
      <MenuItem value="200">Single (200)</MenuItem>
      <MenuItem value="409">Multi Match (409)</MenuItem>
      <MenuItem value="404">No Match (404)</MenuItem>
      <MenuItem value="299">Sync Fail (299)</MenuItem>
      <MenuItem value="400">Bad Request (400)</MenuItem>
      <MenuItem value="408">Timeout (408)</MenuItem>
    </Select>
  );
};

const SelectMatchStatus: React.FC<GridCellParams> = (cell) => {
  const { onChange } = useSetMatchStatus();

  return <SelectMatchStatusPresentation cell={cell} onChange={onChange} />;
};

export { SelectMatchStatus };
