import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { refreshGridState, toastNotificationState } from "../../atoms";
import { Dialog, DialogProps } from "../Dialog";
import { checkDate, formatDateddMMyyyy, formatDateyyyyMMdd } from "../FormValidation";
import { UpdatePerson } from "../ManagePersonalDetails";
import { tierOptions } from "../constants";
import {
  Address,
  ApiException,
  Person,
  PersonRequest,
  PersonSystemId,
} from "../useApiClient/UtilityClientProxy.generated";
import { getStatusCodeDescription } from "../utilities";

export interface UpdatePersonDialogProps extends Omit<DialogProps, "dialogName" | "dialogTitle"> {
  person: Person;
}

const UpdatePersonDialog: React.FC<UpdatePersonDialogProps> = ({ showDialog, onCancelClick, person, ...props }) => {
  const SYSTEM_SHIELD = "SHIELD";

  const setGridRefresh = useSetRecoilState(refreshGridState);
  const setNotificationState = useSetRecoilState(toastNotificationState);
  const [racId, setRacId] = useState(person.racId);
  const [personId, setPersonId] = useState(person.personId);
  const [tier, setTier] = useState("Red");
  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [surname, setSurname] = useState("");
  const [initials, setInitials] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [mobile, setMobile] = useState("");
  const [homePhone, setHomePhone] = useState("");
  const [workPhone, setWorkPhone] = useState("");
  const [personalEmailAddress, setPersonalEmailAddress] = useState("");
  const [workEmailAddress, setWorkEmailAddress] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [subbuildingNumber, setsubbuildingNumber] = useState("");
  const [poBox, setPoBox] = useState("");
  const [unitFlat, setunitFlat] = useState("");
  const [blockNumber, setBlockNumber] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [lotNumber, setLotNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [country, setCountry] = useState("Australia");
  const [state, setState] = useState("WA");
  const [suburb, setSuburb] = useState("");
  const [postcode, setPostcode] = useState("");
  const [shieldContactId, setShieldContactId] = useState("");
  const [shieldPersonSystemIds, setShieldPersonSystemIds] = useState("");

  const getFailedMemberNotificationMessage = (statusCode: number) => {
    const statusCodeDescription = getStatusCodeDescription(statusCode);
    let notificationMessage = `Request to update member failed. (${statusCode} ${statusCodeDescription})`;

    if (statusCode === 400) {
      notificationMessage += "\nCheck data entered.";
    }

    return notificationMessage;
  };

  useEffect(() => {
    setShieldContactId(shieldContactId);
  }, [shieldContactId]);

  useEffect(() => {
    displayPerson();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person]);

  const [inputError, setInputError] = useState(false);
  useEffect(() => {
    setInputError(inputError);
  }, [inputError]);

  const closeDialog = () => {
    onCancelClick && onCancelClick();
  };

  const personSystemIdsToString = (input?: PersonSystemId[]): string => {
    return (
      input
        ?.filter((x) => x.system === SYSTEM_SHIELD)
        ?.map((x) => x.systemId)
        ?.join(", ") ?? ""
    );
  };

  const stringToShieldIds = (input: string): PersonSystemId[] => {
    const ids = input ? input.split(",") : [];

    return ids.map((x) => {
      return {
        system: SYSTEM_SHIELD,
        systemId: x.trim(),
      };
    });
  };

  const onContinueClick = async () => {
    if (checkDate(dateOfBirth)) {
      const memberAddress: Address = {
        buildingName: buildingName,
        subBuildingNumber: subbuildingNumber,
        unitNumber: unitFlat,
        blockNumber: blockNumber,
        houseNumber: houseNumber,
        lotNumber: lotNumber,
        streetName: streetName,
        poBox: poBox,
        suburb: suburb,
        state: state,
        postcode: postcode,
        country: country,
        qasValidated: false,
      };
      const newMember: PersonRequest = {
        racId: racId,
        title: title,
        tier: tier,
        firstName: firstName,
        middleName: middleName,
        surname: surname,
        initials: initials,
        dateOfBirth: formatDateyyyyMMdd(dateOfBirth),
        gender: gender,
        mobilePhone: mobile,
        homePhone: homePhone,
        workPhone: workPhone,
        personalEmailAddress: personalEmailAddress,
        workEmailAddress: workEmailAddress,
        address: memberAddress,
        postalAddress: memberAddress,
        okToMarket: false,
        personSystemIds: stringToShieldIds(shieldPersonSystemIds),
        personId: personId,
      };

      try {
        await UpdatePerson(person.personId!, newMember);
        onCancelClick && onCancelClick();
        setGridRefresh(true);
      } catch (ex: any) {
        setNotificationState({
          message: getFailedMemberNotificationMessage(ex.status),
          apiException: ex as ApiException,
        });
      }
    } else {
      setNotificationState({
        message: `Date of Birth not valid. Please use DD/MM/YYYY`,
        apiException: null,
      });
    }
  };

  function displayPerson() {
    setRacId(person.racId!);
    setPersonId(person.personId!);
    setTier(person.tier!);
    setFirstName(person.firstName!);
    setTitle(person.title!);
    setFirstName(person.firstName!);
    setMiddleName(person.middleName!);
    setSurname(person.surname!);
    setInitials(person.initials!);
    setDateOfBirth(formatDateddMMyyyy(person.dateOfBirth!));
    setGender(person.gender!);
    setMobile(person.mobilePhone!);
    setHomePhone(person.homePhone!);
    setWorkPhone(person.workPhone!);
    setPersonalEmailAddress(person.personalEmailAddress!);
    setWorkEmailAddress(person.workEmailAddress!);
    setBuildingName(person.postalAddress?.buildingName!);
    setsubbuildingNumber(person.postalAddress?.subBuildingNumber!);
    setPoBox(person.postalAddress?.poBox!);
    setunitFlat(person.postalAddress?.unitNumber!);
    setBlockNumber(person.postalAddress?.blockNumber!);
    setHouseNumber(person.postalAddress?.houseNumber!);
    setLotNumber(person.postalAddress?.lotNumber!);
    setStreetName(person.postalAddress?.streetName!);
    setCountry(person.postalAddress?.country!);
    setState(person.postalAddress?.state!);
    setSuburb(person.postalAddress?.suburb!);
    setPostcode(person.postalAddress?.postcode!);
    setShieldPersonSystemIds(personSystemIdsToString(person.personSystemIds));
  }
  return (
    <Dialog
      showDialog={showDialog}
      dialogName="update-person-dialog"
      dialogTitle="Update Person"
      onCancelClick={closeDialog}
      onContinueClick={onContinueClick}
      {...props}
    >
      <table>
        <tr>
          <td>Rac Id</td>
          <td>
            <label>{racId}</label>
          </td>
        </tr>
        <tr>
          <td>Person Id / Crm Id</td>
          <td>
            <input
              type="text"
              value={personId}
              maxLength={40}
              size={36}
              onChange={(e) => setPersonId(e.target.value)}
            />
          </td>
        </tr>
        <tr>
          {" "}
          <td>Linked Shield Ids (CSVs)</td>
          <td>
            <input
              onChange={(e) => setShieldPersonSystemIds(e.target.value)}
              type="text"
              value={shieldPersonSystemIds}
            ></input>
          </td>
        </tr>
        <tr>
          <td>Membership Tier</td>
          <td>
            <select value={tier} onChange={(e) => setTier(e.target.value)}>
              {tierOptions.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Title </td>
          <td>
            <input
              onChange={(e) => setTitle(e.target.value)}
              type="text"
              maxLength={20}
              size={20}
              value={title}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>First Name </td>
          <td>
            <input
              type="text"
              value={firstName}
              maxLength={50}
              size={30}
              onChange={(e) => setFirstName(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Middle Name </td>
          <td>
            <input
              type="text"
              value={middleName}
              maxLength={55}
              size={30}
              onChange={(e) => setMiddleName(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Surname </td>
          <td>
            <input
              type="text"
              value={surname}
              maxLength={55}
              size={30}
              onChange={(e) => setSurname(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Initials </td>
          <td>
            <input type="text" value={initials} size={5} onChange={(e) => setInitials(e.target.value)}></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td width={200}>Date Of Birth (DD/MM/YYYY) </td>
          <td>
            <input
              type="text"
              value={dateOfBirth}
              maxLength={10}
              size={10}
              onChange={(e) => setDateOfBirth(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Gender </td>
          <td>
            <input type="text" value={gender} size={10} onChange={(e) => setGender(e.target.value)}></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Mobile </td>
          <td>
            <input
              type="text"
              value={mobile}
              maxLength={10}
              size={10}
              onChange={(e) => setMobile(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Home Phone </td>
          <td>
            <input
              type="text"
              value={homePhone}
              maxLength={10}
              size={10}
              onChange={(e) => setHomePhone(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Work Phone </td>
          <td>
            <input
              type="text"
              value={workPhone}
              maxLength={10}
              size={10}
              onChange={(e) => setWorkPhone(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Personal EmailAddress </td>
          <td>
            <input
              type="text"
              value={personalEmailAddress}
              maxLength={50}
              size={30}
              onChange={(e) => setPersonalEmailAddress(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Work EmailAddress </td>
          <td>
            <input
              type="text"
              value={workEmailAddress}
              maxLength={50}
              size={30}
              onChange={(e) => setWorkEmailAddress(e.target.value)}
            ></input>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          {" "}
          <td>Building Name</td>
          <td>
            <input
              type="text"
              value={buildingName}
              maxLength={50}
              onChange={(e) => setBuildingName(e.target.value)}
            ></input>
          </td>
          <td>Subbuilding Number </td>
          <td>
            <input type="text" value={subbuildingNumber} onChange={(e) => setsubbuildingNumber(e.target.value)}></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>PO Box </td>
          <td>
            <input type="text" value={poBox} maxLength={20} onChange={(e) => setPoBox(e.target.value)}></input>
          </td>
          <td>Unit/Flat</td>
          <td>
            <input type="text" value={unitFlat} maxLength={9} onChange={(e) => setunitFlat(e.target.value)}></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Block Number </td>
          <td>
            <input
              type="text"
              value={blockNumber}
              maxLength={10}
              onChange={(e) => setBlockNumber(e.target.value)}
            ></input>
          </td>
          <td>Number </td>
          <td>
            <input
              type="text"
              value={houseNumber}
              maxLength={14}
              onChange={(e) => setHouseNumber(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Lot Number </td>
          <td>
            <input type="text" value={lotNumber} maxLength={14} onChange={(e) => setLotNumber(e.target.value)}></input>
          </td>
          <td>Street Name </td>
          <td>
            <input
              type="text"
              value={streetName}
              maxLength={255}
              onChange={(e) => setStreetName(e.target.value)}
            ></input>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <td width={100}>Country</td>
          <td>
            <input
              type="text"
              name={country}
              value={country}
              maxLength={100}
              size={70}
              onChange={(e) => setCountry(e.target.value)}
            ></input>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          {" "}
          <td width={100}>State </td>
          <td>
            <input
              type="text"
              name={state}
              value={state}
              maxLength={10}
              size={10}
              onChange={(e) => setState(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Suburb </td>
          <td>
            <input type="text" value={suburb} onChange={(e) => setSuburb(e.target.value)}></input>
          </td>
          <td>Postcode </td>
          <td>
            <input type="text" maxLength={4} value={postcode} onChange={(e) => setPostcode(e.target.value)}></input>
          </td>
        </tr>
      </table>
    </Dialog>
  );
};

export default UpdatePersonDialog;
