import { isNonProdEnvironment } from "../isNonProdEnvironment";
import { UtilityApiClient } from "./UtilityClientProxy.generated";

const createApiWrapper = () => {
  if (isNonProdEnvironment()) {
    return async (url: RequestInfo, init?: RequestInit): Promise<Response> => {
      const token = require("../RacwaAuth").getToken();
      const headers: any = { ...init?.headers };
      if (token) {
        headers.Authorization = "Bearer ".concat(token);
      }
      const response = await fetch(url, {
        ...init,
        headers,
      });
      return response;
    };
  } else {
    return async (url: RequestInfo, init?: RequestInit): Promise<Response> => {
      const headers: any = { ...init?.headers };
      const response = await fetch(url, {
        ...init,
        headers,
      });
      return response;
    };
  }
};

const utilityUrl = (process.env.REACT_APP_API_BASE_URL || "") + (process.env.REACT_APP_API_UTILITY_URL || "");

export const utilityApiClient = new UtilityApiClient(utilityUrl, {
  fetch: createApiWrapper(),
});

export const useApiClient = (): UtilityApiClient => utilityApiClient;

export default useApiClient;
