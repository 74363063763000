import { Snackbar, snackbarClasses, styled } from "@mui/material";
import { colors } from "@racwa/styles";

export const StyledSnackBar = styled(Snackbar)(({ theme }) => ({
  [`& .${snackbarClasses.root}`]: {
    minWidth: "450px",
    maxWidth: "600px",
    minHeight: "50px",
    maxHeight: "100px",
    background: colors.dieselMid,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "3px",
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
    container: {
      [theme.breakpoints.up("lg")]: { marginLeft: "50px" },
    },
    iconButton: {
      color: "#ffffff",
    },
  },
}));

export default StyledSnackBar;
