import { BodyCopy } from "@racwa/react-components";
import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { environmentState, refreshGridState, toastNotificationState } from "../../atoms";
import { REGEX_NUMERIC } from "../constants";
import { Dialog, DialogProps } from "../Dialog";
import LinkOrganisationInput from "../LinkOrganisationInput";
import { ApiException } from "../useApiClient/UtilityClientProxy.generated";
import { useGetOrganisationByShieldContactId } from "../utilityApi";

export interface LinkOrganisationDialogProps extends Omit<DialogProps, "dialogName" | "dialogTitle"> {}

const LinkOrganisationDialog: React.FC<LinkOrganisationDialogProps> = ({ showDialog, onCancelClick, ...props }) => {
  const environment = useRecoilValue(environmentState);
  const setGridRefresh = useSetRecoilState(refreshGridState);
  const setNotificationState = useSetRecoilState(toastNotificationState);
  const getOrganisationByShieldContactId = useGetOrganisationByShieldContactId();

  const [shieldContactId, setShieldContactId] = useState("");
  useEffect(() => {
    setShieldContactId(shieldContactId);
  }, [shieldContactId]);

  const [inputError, setInputError] = useState(false);
  useEffect(() => {
    setInputError(inputError);
  }, [inputError]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "" || REGEX_NUMERIC.test(event.target.value)) {
      setInputError(false);
    } else {
      setInputError(true);
    }
    setShieldContactId(event.target.value);
  };

  const closeDialog = () => {
    onCancelClick && onCancelClick();
    setShieldContactId("");
    setInputError(false);
  };

  const onContinueClick = async () => {
    if (
      shieldContactId === undefined ||
      shieldContactId === null ||
      shieldContactId === "" ||
      !REGEX_NUMERIC.test(shieldContactId)
    ) {
      setInputError(true);
    } else {
      try {
        closeDialog();
        await getOrganisationByShieldContactId(shieldContactId);
        setGridRefresh(true);
        setNotificationState({
          message: `Request to link organisation from ${environment} succeeded.`,
        });
      } catch (ex: any) {
        setNotificationState({
          message: `Request to link organisation from ${environment} failed.`,
          apiException: ex as ApiException,
        });
      }
    }
  };

  return (
    <Dialog
      showDialog={showDialog}
      dialogName="link-organisation-dialog"
      dialogTitle="Link Organisation"
      onCancelClick={closeDialog}
      onContinueClick={onContinueClick}
      {...props}
    >
      <BodyCopy gutterBottom>
        <strong>
          This will create a link between a Shield Contact from environment <b>{environment}</b> to a organisation in
          the MemberCentralMock database.
        </strong>
      </BodyCopy>
      <LinkOrganisationInput
        {...props}
        inputValue={shieldContactId}
        isError={inputError}
        onChange={handleInputChange}
      />
    </Dialog>
  );
};

export default LinkOrganisationDialog;
