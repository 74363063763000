export const HTTP_STATUS_CODE_OK = 200;
export const HTTP_STATUS_CODE_NOCONTENT = 204;
export const HTTP_STATUS_CODE_FORBIDDEN = 403;
export const SESSION_KEY_PREFIX = "RAC_MCMOCK_";
export const REGEX_NUMERIC = new RegExp(/^[0-9\b]+$/);
export const AZURE_AD_TOKEN_EXPIRED_MESSAGE = "Azure authentication token has expired.";

export const CONTACT_NUMBER_LENGTH = 10;
export const EMAIL_LENGTH = 50;

export const tierOptions = [
  {
    label: "Red (No RSA)",
    value: "Red",
  },
  {
    label: "Blue (0-4 years)",
    value: "Blue",
  },
  {
    label: "Bronze (5-9 years)",
    value: "Bronze",
  },
  {
    label: "Silver (10-24 years)",
    value: "Silver",
  },
  {
    label: "Gold (25-49 years)",
    value: "Gold",
  },
  {
    label: "Gold Life (50+ years)",
    value: "Gold Life",
  },
];
