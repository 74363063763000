import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { refreshGridState, toastNotificationState } from "../../atoms";
import { Dialog, DialogProps } from "../Dialog";
import { checkDate, formatDateyyyyMMdd } from "../FormValidation";
import { CreatePerson } from "../ManagePersonalDetails";
import { CONTACT_NUMBER_LENGTH, EMAIL_LENGTH, tierOptions } from "../constants";
import { Address, ApiException, PersonRequest } from "../useApiClient/UtilityClientProxy.generated";
import { getStatusCodeDescription } from "../utilities";
export interface CreatePersonDialogProps extends Omit<DialogProps, "dialogName" | "dialogTitle"> {}

const CreatePersonDialog: React.FC<CreatePersonDialogProps> = ({ showDialog, onCancelClick, ...props }) => {
  const setGridRefresh = useSetRecoilState(refreshGridState);
  const setNotificationState = useSetRecoilState(toastNotificationState);
  const [racId, setRacId] = useState("");
  const [personId, setPersonId] = useState("");
  const [tier, setTier] = useState("Red");
  const [title, settitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [surname, setSurname] = useState("");
  const [initials, setInitials] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [mobile, setMobile] = useState("");
  const [homePhone, setHomePhone] = useState("");
  const [workPhone, setWorkPhone] = useState("");
  const [personalEmailAddress, setPersonalEmailAddress] = useState("");
  const [workEmailAddress, setWorkEmailAddress] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [subbuildingNumber, setsubbuildingNumber] = useState("");
  const [poBox, setPoBox] = useState("");
  const [unitFlat, setunitFlat] = useState("");
  const [blockNumber, setBlockNumber] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [lotNumber, setLotNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [country, setCountry] = useState("Australia");
  const [state, setState] = useState("WA");
  const [suburb, setSuburb] = useState("");
  const [postcode, setPostcode] = useState("");
  const [shieldContactId, setShieldContactId] = useState("");
  const [completed, setCompleted] = useState(false);

  const getFailedMemberNotificationMessage = (statusCode: number) => {
    const statusCodeDescription = getStatusCodeDescription(statusCode);
    let notificationMessage = `Request to create member failed. (${statusCode} ${statusCodeDescription})`;

    if (statusCode === 400) {
      notificationMessage += "\nCheck data entered.";
    }

    return notificationMessage;
  };

  useEffect(() => {
    setShieldContactId(shieldContactId);
  }, [shieldContactId]);

  const [inputError, setInputError] = useState(false);
  useEffect(() => {
    setInputError(inputError);
  }, [inputError]);

  const closeDialog = () => {
    setCompleted(false);
    onCancelClick && onCancelClick();
  };

  function ClearForm() {
    setRacId("");
    setPersonId("");
    setTier("Red");
    settitle("");
    setFirstName("");
    setMiddleName("");
    setSurname("");
    setInitials("");
    setDateOfBirth("");
    setGender("");
    setMobile("");
    setHomePhone("");
    setWorkPhone("");
    setPersonalEmailAddress("");
    setWorkEmailAddress("");
    setBuildingName("");
    setsubbuildingNumber("");
    setPoBox("");
    setunitFlat("");
    setBlockNumber("");
    setHouseNumber("");
    setLotNumber("");
    setStreetName("");
    setCountry("Australia");
    setState("WA");
    setSuburb("");
    setPostcode("");
  }

  const onContinueClick = async () => {
    if (completed) {
      setCompleted(false);
      ClearForm();
      closeDialog();
      setGridRefresh(true);
    } else {
      let noErrors = true;

      if (!checkDate(dateOfBirth)) {
        setNotificationState({
          message: `Please check date of birth and try again. `,
        });
        noErrors = false;
      }

      if (noErrors) {
        const memberAddress: Address = {
          buildingName: buildingName,
          subBuildingNumber: subbuildingNumber,
          unitNumber: unitFlat,
          blockNumber: blockNumber,
          houseNumber: houseNumber,
          lotNumber: lotNumber,
          streetName: streetName,
          poBox: poBox,
          suburb: suburb,
          state: state,
          postcode: postcode,
          country: country,
          qasValidated: false,
        };

        const newMember: PersonRequest = {
          title: title,
          tier: tier,
          firstName: firstName,
          middleName: middleName,
          surname: surname,
          initials: initials,
          dateOfBirth: formatDateyyyyMMdd(dateOfBirth),
          gender: gender,
          mobilePhone: mobile,
          homePhone: homePhone,
          workPhone: workPhone,
          personalEmailAddress: personalEmailAddress,
          workEmailAddress: workEmailAddress,
          address: memberAddress,
          postalAddress: memberAddress,
          okToMarket: false,
        };

        try {
          const newPerson = await CreatePerson(newMember);
          setRacId(newPerson.result.racId!);
          setPersonId(newPerson.result.personId!);

          setNotificationState({
            message: `Request to create member succeeded. Click on Continue to return to main page `,
          });
          setCompleted(true);
        } catch (ex: any) {
          setNotificationState({
            message: getFailedMemberNotificationMessage(ex.status),
            apiException: ex as ApiException,
          });

          if (ex.status === 299) {
            closeDialog();
            setGridRefresh(true);
          } else if (ex.status !== 400) {
            closeDialog();
          }
        }
      }
    }
  };

  return (
    <Dialog
      showDialog={showDialog}
      dialogName="create-person-dialog"
      dialogTitle="Create Person"
      onCancelClick={closeDialog}
      onContinueClick={onContinueClick}
      {...props}
    >
      <table>
        <tr>
          <td>Rac Id</td>
          <td>
            <label>{racId}</label>
          </td>
        </tr>
        <tr>
          <td>Person Id / Crm Id</td>
          <td>
            <label>{personId}</label>
          </td>
        </tr>
        <tr>
          <td>Membership Tier</td>
          <td>
            <select onChange={(e) => setTier(e.target.value)}>
              {tierOptions.map((option) => (
                <option value={option.value}>{option.label}</option>
              ))}
            </select>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Title </td>
          <td>
            <input onChange={(e) => settitle(e.target.value)} type="text" maxLength={20} size={20} name={title}></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>First Name </td>
          <td>
            <input
              type="text"
              name={firstName}
              maxLength={50}
              size={30}
              onChange={(e) => setFirstName(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Middle Name </td>
          <td>
            <input
              type="text"
              name={middleName}
              maxLength={55}
              size={30}
              onChange={(e) => setMiddleName(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Surname </td>
          <td>
            <input
              type="text"
              name={surname}
              maxLength={55}
              size={30}
              onChange={(e) => setSurname(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Initials </td>
          <td>
            <input type="text" name={initials} size={5} onChange={(e) => setInitials(e.target.value)}></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td width={200}>Date Of Birth (DD/MM/YYYY) </td>
          <td>
            <input
              type="text"
              name={dateOfBirth}
              maxLength={10}
              size={10}
              onChange={(e) => setDateOfBirth(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Gender </td>
          <td>
            <input type="text" name={gender} size={10} onChange={(e) => setGender(e.target.value)}></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Mobile </td>
          <td>
            <input
              type="text"
              name={mobile}
              maxLength={CONTACT_NUMBER_LENGTH}
              size={CONTACT_NUMBER_LENGTH}
              onChange={(e) => setMobile(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Home Phone </td>
          <td>
            <input
              type="text"
              name={homePhone}
              maxLength={CONTACT_NUMBER_LENGTH}
              size={CONTACT_NUMBER_LENGTH}
              onChange={(e) => setHomePhone(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Work Phone </td>
          <td>
            <input
              type="text"
              name={workPhone}
              maxLength={CONTACT_NUMBER_LENGTH}
              size={CONTACT_NUMBER_LENGTH}
              onChange={(e) => setWorkPhone(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Personal EmailAddress </td>
          <td>
            <input
              type="text"
              name={personalEmailAddress}
              maxLength={EMAIL_LENGTH}
              size={30}
              onChange={(e) => setPersonalEmailAddress(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Work EmailAddress </td>
          <td>
            <input
              type="text"
              name={workEmailAddress}
              maxLength={EMAIL_LENGTH}
              size={30}
              onChange={(e) => setWorkEmailAddress(e.target.value)}
            ></input>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          {" "}
          <td>Building Name</td>
          <td>
            <input
              type="text"
              name={buildingName}
              maxLength={50}
              onChange={(e) => setBuildingName(e.target.value)}
            ></input>
          </td>
          <td>Subbuilding Number </td>
          <td>
            <input type="text" name={subbuildingNumber} onChange={(e) => setsubbuildingNumber(e.target.value)}></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>PO Box </td>
          <td>
            <input type="text" name={poBox} maxLength={20} onChange={(e) => setPoBox(e.target.value)}></input>
          </td>
          <td>Unit/Flat</td>
          <td>
            <input type="text" name={unitFlat} maxLength={9} onChange={(e) => setunitFlat(e.target.value)}></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Block Number </td>
          <td>
            <input
              type="text"
              name={blockNumber}
              maxLength={10}
              onChange={(e) => setBlockNumber(e.target.value)}
            ></input>
          </td>
          <td>Number </td>
          <td>
            <input
              type="text"
              name={houseNumber}
              maxLength={14}
              onChange={(e) => setHouseNumber(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Lot Number </td>
          <td>
            <input type="text" name={lotNumber} maxLength={14} onChange={(e) => setLotNumber(e.target.value)}></input>
          </td>
          <td>Street Name </td>
          <td>
            <input
              type="text"
              name={streetName}
              maxLength={255}
              onChange={(e) => setStreetName(e.target.value)}
            ></input>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <td width={100}>Country</td>
          <td>
            <input
              type="text"
              name={country}
              value={country}
              maxLength={100}
              size={70}
              onChange={(e) => setCountry(e.target.value)}
            ></input>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          {" "}
          <td width={100}>State </td>
          <td>
            <input
              type="text"
              name={state}
              value={state}
              maxLength={10}
              size={10}
              onChange={(e) => setState(e.target.value)}
            ></input>
          </td>
        </tr>
        <tr>
          {" "}
          <td>Suburb </td>
          <td>
            <input type="text" name={suburb} onChange={(e) => setSuburb(e.target.value)}></input>
          </td>
          <td>Postcode </td>
          <td>
            <input type="text" maxLength={4} name={postcode} onChange={(e) => setPostcode(e.target.value)}></input>
          </td>
        </tr>
      </table>
    </Dialog>
  );
};

export default CreatePersonDialog;
