import { Receipt } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";

export interface ShowProductHoldingsButtonProps {
  onClick?: () => void;
}

const ShowProductHoldingsButton: React.FC<ShowProductHoldingsButtonProps> = ({ onClick }) => {
  const buttonName = "btn-show-product-holdings";
  return (
    <Button
      id={buttonName}
      title={buttonName}
      name={buttonName}
      startIcon={<Receipt />}
      size="small"
      onClick={onClick}
      style={{ whiteSpace: "initial" }}
    >
      Product Holdings
    </Button>
  );
};
export default ShowProductHoldingsButton;
