// Copied from https://github.com/ECFMG/msal-react-lite/tree/f97ae82882ffbc6fbb42405cff5d1f49f0aeda07
import { createContext } from "react";

export interface MsalContextInterface {
  getAuthToken: () => Promise<string | undefined>;
  isLoggedIn: boolean;
  logout: () => Promise<void>;
  login: () => Promise<void>;
}

const stub = (): never => {
  throw new Error("You forgot to wrap your component in <MsalProvider>.");
};

const initialContext = {
  getAuthToken: stub,
  isLoggedIn: false,
  logout: stub,
  login: stub,
};

const MsalContext = createContext<MsalContextInterface>(initialContext);

export default MsalContext;
