import { useEffect, useState } from "react";
import MsalProvider, { MsalProviderRedirectConfig, useMsal } from "../MsalReactLite";

const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID ?? "missing-client-id";
const appAuthority = process.env.REACT_APP_OAUTH_AUTHORITY ?? "missing-tenant-id";
const redirectUri = window.location.origin;

const config: MsalProviderRedirectConfig = {
  type: "redirect",
  msalConfig: {
    auth: {
      clientId: clientId,
      authority: appAuthority,
      redirectUri: redirectUri,
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true,
    },
  },
  silentRequestConfig: {
    scopes: [],
  },
  endSessionRequestConfig: {},
  redirectRequestConfig: {
    scopes: [],
  },
};

export function getToken() {
  try {
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i) || "";
      try {
        const item = JSON.parse(sessionStorage.getItem(key) || "") || null;
        if (item?.credentialType === "IdToken") {
          return item?.secret || "";
        }
      } catch {
        // DO NOTHING
      }
    }
  } catch {
    // DO NOTHING
  }

  return "";
}

interface InitialiserProps {
  setIsLoaded: (isLoaded: boolean) => void;
}

export type RacwaAuthProps = {
  children?: React.ReactNode;
};

const Initialiser: React.FC<InitialiserProps> = ({ setIsLoaded }) => {
  const { login } = useMsal();

  useEffect(() => {
    let unmounted = false;
    const tryAndAuthenticate = async () => {
      if (!getToken()) {
        await login();
      }

      if (!unmounted) {
        setIsLoaded(!!getToken());
      }
    };

    tryAndAuthenticate();
    return () => {
      unmounted = true;
    };
  }, [login, setIsLoaded]);

  return null;
};

const RacwaAuth: React.FC<RacwaAuthProps> = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <MsalProvider config={config}>
      <Initialiser setIsLoaded={setIsLoaded} />
      {isLoaded ? children : null}
    </MsalProvider>
  );
};

export default RacwaAuth;
