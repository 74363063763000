import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { toastNotificationState } from "../../atoms";
import { Dialog, DialogProps } from "../Dialog";
import ProductHoldingsTable from "../ProductHoldingsTable";
import { PersonProductHolding } from "../useApiClient/UtilityClientProxy.generated";
import { PopulateGrid } from "./PopulateGrid";

export interface ProductHoldingsDialogProps extends Omit<DialogProps, "dialogName" | "dialogTitle"> {
  contactId: string | undefined;
}

const ProductHoldingsDialog: React.FC<ProductHoldingsDialogProps> = ({
  showDialog,
  onCancelClick,
  contactId,
  ...props
}) => {
  const setNotificationState = useSetRecoilState(toastNotificationState);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState<PersonProductHolding[]>([]);

  useEffect(() => {
    if (showDialog) {
      PopulateGrid(contactId, setRows, setIsLoading, setNotificationState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDialog]);

  const closeDialog = () => {
    onCancelClick && onCancelClick();
  };

  return (
    <Dialog
      showDialog={showDialog}
      dialogName="product-holdings-dialog"
      dialogTitle="Product Holdings"
      maxWidth="md"
      onCancelClick={closeDialog}
      {...props}
    >
      {isLoading ? (
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <ProductHoldingsTable rows={rows} />
      )}
    </Dialog>
  );
};

export default ProductHoldingsDialog;
