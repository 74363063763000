import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { toastNotificationState } from "../../atoms";
import { SearchByShieldContactId } from "../ManagePersonalDetails";
import ProductHoldingsDialog from "../ProductHoldingsDialog";
import UpdatePersonDialog from "../UpdatePersonDialog";
import { ApiException, Person } from "../useApiClient/UtilityClientProxy.generated";
import { BindGrid } from "./BindGrid";
import GridToolbar from "./gridToolbar";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

export const PersonGrid = (props: TabPanelProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { value, index } = props;

  const response = BindGrid({
    setIsLoading,
    selectPerson,
    showProductHoldings,
  });

  const [showUpdatePersonDialog, setShowUpdatePersonDialog] = useState(false);
  const [showProductHoldingsDialog, setShowProductHoldingsDialog] = useState(false);

  const setNotificationState = useSetRecoilState(toastNotificationState);

  let person: Person = {
    eligibleToVote: false,
    isMember: false,
  };
  const [target, setTarget] = useState(person);

  async function selectPerson(id: string) {
    try {
      const searchResult = await SearchByShieldContactId(id);
      person = searchResult.result;
      setTarget(person);
      setShowUpdatePersonDialog(true);
    } catch (ex: any) {
      setNotificationState({
        message: `Request to get member failed. (503 Server Unavailable)`,
        apiException: ex as ApiException,
      });
    }
  }

  const [contactId, setContactId] = useState<string>();

  function showProductHoldings(id: string) {
    setContactId(id);
    setShowProductHoldingsDialog(true);
  }

  return (
    <div hidden={value !== index}>
      <DataGrid
        rows={response.data.rows}
        columns={response.data.columns}
        components={{
          Toolbar: GridToolbar,
        }}
        loading={isLoading}
        style={{ height: "85vh" }}
      ></DataGrid>
      <div>
        <UpdatePersonDialog
          person={target}
          showDialog={showUpdatePersonDialog}
          onCancelClick={() => setShowUpdatePersonDialog(false)}
        />
        <ProductHoldingsDialog
          contactId={contactId}
          showDialog={showProductHoldingsDialog}
          onCancelClick={() => setShowProductHoldingsDialog(false)}
        />
      </div>
    </div>
  );
};

export const CustomTabPane = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default PersonGrid;
