export interface EnvironmentSettings {
  retrieveAll: () => string[];
  current: () => string;
  setEnv: (value: string) => void;
}

export const environmentSettings = (): EnvironmentSettings => {
  const key = "shieldEnv";
  const retrieveAll = () => JSON.parse(process.env.REACT_APP_ENVIRONMENTS || "[]");
  const current = () => localStorage.getItem(key) || retrieveAll()[0];
  const set = (value: string) => localStorage.setItem(key, value);

  return { retrieveAll, current, setEnv: set };
};

export default environmentSettings;
