import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { PersonProductHolding } from "../useApiClient/UtilityClientProxy.generated";
import ProductHoldingRow from "./ProductHoldingRow";

export interface ProductHoldingsTableProps {
  rows: PersonProductHolding[];
}

const ProductHoldingsTable: React.FC<ProductHoldingsTableProps> = ({ rows }) => {
  const [rowStates, setRowStates] = React.useState(rows.map((_) => false));

  function toggleRow(index: number) {
    const newRowStates = rowStates.map((rowState, rowIndex) => (index === rowIndex ? !rowState : rowState));

    setRowStates(newRowStates);
  }

  function openAllRows(open: boolean) {
    const newRowStates = rowStates.map((_) => open);

    setRowStates(newRowStates);
  }

  return (
    <>
      <Grid container justifyContent="flex-end">
        <ButtonGroup>
          <Button startIcon={<ExpandMore />} size="small" onClick={() => openAllRows(true)}>
            Expand All
          </Button>
          <Button startIcon={<ExpandLess />} size="small" onClick={() => openAllRows(false)}>
            Collapse All
          </Button>
        </ButtonGroup>
      </Grid>

      <TableContainer style={{ overflowX: "hidden", marginTop: "8px" }}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Timestamp</TableCell>
              <TableCell>Payload</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <ProductHoldingRow key={index} index={index} row={row} open={rowStates[index]} toggle={toggleRow} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ProductHoldingsTable;
