import { BodyCopy } from "@racwa/react-components";
import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { environmentState, refreshGridState, toastNotificationState } from "../../atoms";
import { backdropState } from "../../selectors";
import { Dialog, DialogProps } from "../Dialog";
import { ApiException } from "../useApiClient/UtilityClientProxy.generated";
import { useClearMembers } from "../utilityApi";

export interface ClearEnvironmentDialogProps extends Omit<DialogProps, "dialogName" | "dialogTitle"> {}

const ClearEnvironmentDialog: React.FC<ClearEnvironmentDialogProps> = ({ showDialog, onCancelClick, ...props }) => {
  const environment = useRecoilValue(environmentState);
  const setGridRefresh = useSetRecoilState(refreshGridState);
  const setNotificationState = useSetRecoilState(toastNotificationState);
  const setBackdrop = useSetRecoilState(backdropState);

  const clearMembers = useClearMembers();

  const closeDialog = () => {
    onCancelClick && onCancelClick();
  };

  const onContinueClick = async () => {
    try {
      setBackdrop(true);
      closeDialog();
      await clearMembers();
      setGridRefresh(true);
      setNotificationState({
        message: `Request to clear environment ${environment} succeeded.`,
      });
    } catch (ex: any) {
      setNotificationState({
        message: `Request to clear environment ${environment} failed.`,
        apiException: ex as ApiException,
      });
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <Dialog
      showDialog={showDialog}
      dialogName={"clear-environment-dialog"}
      dialogTitle={"Are you sure?"}
      onCancelClick={closeDialog}
      onContinueClick={onContinueClick}
      {...props}
    >
      <BodyCopy gutterBottom>
        <strong>
          Clearing the Member data for environment <b>{environment}</b> will impact other users!
          <br />
          Please take this into consideration before clicking Continue.
          <br />
          Otherwise, click Cancel or close the dialog.
        </strong>
      </BodyCopy>
    </Dialog>
  );
};

export default ClearEnvironmentDialog;
