import { isValid, parse } from "date-fns";
import { enGB } from "date-fns/locale";

// Checks if a string date in the format dd/MM/YYYY is a valid date
function checkDate(dateString: string) {
  const regex = /^\d{2}\/\d{2}\/\d{4}$/;

  if (isEmptyOrSpaces(dateString)) {
    return true;
  }

  if (dateString.match(regex) == null) {
    return false;
  }

  const parsedDate = parse(dateString, "P", new Date(), { locale: enGB });
  if (isValid(parsedDate)) {
    return true;
  } else {
    return false;
  }
}

function isEmptyOrSpaces(str: string) {
  return str === null || str.match(/^ *$/) !== null;
}

function IsOnlyNumbers(str: string) {
  return /^\d+$/.test(str);
}

// changes a string date from dd/MM/yyyy to yyyy/MM/dd
function formatDateyyyyMMdd(dateReceived: string) {
  if (dateReceived != null && dateReceived.length === 10) {
    return dateReceived.substring(6) + "/" + dateReceived.substring(3, 5) + "/" + dateReceived.substring(0, 2);
  } else {
    return "";
  }
}

function formatDateddMMyyyy(dateReceived: string) {
  if (dateReceived != null && dateReceived.length === 10) {
    return dateReceived.substring(8) + "/" + dateReceived.substring(5, 7) + "/" + dateReceived.substring(0, 4);
  }

  return "";
}

function stringToInt(digits: string) {
  return parseInt(digits);
}

export { IsOnlyNumbers, checkDate, formatDateddMMyyyy, formatDateyyyyMMdd, isEmptyOrSpaces, stringToInt };
