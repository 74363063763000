export const getStatusCodeDescription = (statusCode: number) => {
  switch (statusCode) {
    case 299:
      return "Sync Error";
    case 400:
      return "Bad Request";
    case 401:
      return "Unauthorised";
    case 500:
      return "Server Exception";
    case 501:
      return "Server Unavailable";
    default:
      return "Unknown Status Code";
  }
};
