import { MenuItem, Select, styled } from "@mui/material";
import { RecoilState, useRecoilState, useSetRecoilState } from "recoil";
import { toastNotificationState } from "../../atoms";
import { backdropState } from "../../selectors";
import { StatusCodeSetting } from "../Settings/statusCodeSettings";
import { ApiException } from "../useApiClient/UtilityClientProxy.generated";

interface SetDefaultResponseProps {
  name: string;
  title: string;
  label: string;
  statusCodeStateKey: RecoilState<number>;
  items: StatusCodeSetting[];
  setRemoteStatusCode: (statusCode: string) => Promise<void>;
}

const StyledHeader = styled("h3")(({ theme }) => ({
  display: "inline",
  marginRight: theme.spacing(0.5),
}));

export const SetDefaultResponse: React.FC<SetDefaultResponseProps> = (props): JSX.Element => {
  const setNotificationState = useSetRecoilState(toastNotificationState);
  const setBackdrop = useSetRecoilState(backdropState);
  const [statusCode, setStatusCode] = useRecoilState(props.statusCodeStateKey);

  const onChange = async (statusCode: any) => {
    try {
      setBackdrop(true);
      await props.setRemoteStatusCode(statusCode);
      setNotificationState({
        message: `${props.label} changed to ${statusCode}`,
      });
      setStatusCode(parseInt(statusCode));
    } catch (ex: any) {
      setNotificationState({
        message: `Request to update ${props.label} failed.`,
        apiException: ex as ApiException,
      });
    } finally {
      setBackdrop(false);
    }
  };

  const statusCodeElements = props.items.map((status: StatusCodeSetting) => {
    return (
      <MenuItem key={status.code} value={status.code}>
        {status.description} ({status.code})
      </MenuItem>
    );
  });

  return (
    <>
      <StyledHeader>{props.label}</StyledHeader>
      <Select
        name={props.name}
        title={props.title}
        value={statusCode}
        fullWidth={false}
        onChange={(e) => onChange(e.target.value)}
      >
        {statusCodeElements}
      </Select>
    </>
  );
};
