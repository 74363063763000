import { Button, buttonBaseClasses, DialogActions, dialogActionsClasses, styled } from "@mui/material";

export const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${buttonBaseClasses.root}`]: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "135px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingLeft: "10px",
      paddingRight: "8px",
    },
  },
}));

export const StyledDialogActions = styled(DialogActions)({
  [`&.${dialogActionsClasses.root}`]: {
    paddingLeft: 0,
    paddingRight: 1,
  },
});
