export interface StatusCodeSetting {
  code: number;
  description: string;
}

export const retrieveIsAliveStatusCodes: () => StatusCodeSetting[] = () =>
  retrieveStatusCodes(process.env.REACT_APP_ISALIVE_STATUSCODES);

export const retrieveProductHoldingStatusCodes: () => StatusCodeSetting[] = () =>
  retrieveStatusCodes(process.env.REACT_APP_PRODUCTHOLDING_STATUSCODES);

export const retrieveStatusOverrideStatusCodes: () => StatusCodeSetting[] = () =>
  retrieveStatusCodes(process.env.REACT_APP_STATUSOVERRIDE_STATUSCODES);

const retrieveStatusCodes = (envKey: string | undefined): StatusCodeSetting[] => {
  return JSON.parse(envKey || "[]") as StatusCodeSetting[];
};
