import { HTTP_STATUS_CODE_FORBIDDEN } from "../constants";
import environmentSettings from "../Settings/environmentSettings";
import { useApiClient } from "../useApiClient";
import { OrganisationRequest, PersonRequest } from "../useApiClient/UtilityClientProxy.generated";

const shieldEnvironment = environmentSettings().current;

function useClearMembers() {
  const client = useApiClient();
  return async () => await client.clearmembers(shieldEnvironment());
}

function useSetIsAlive() {
  const client = useApiClient();
  return async (statusCode: string) => {
    await client.setisalive(shieldEnvironment(), { statusCode: statusCode });
  };
}

function useGetIsAlive() {
  const client = useApiClient();
  return async (environment: string) => await client.getisalive(environment);
}

function useSearchMembers() {
  const client = useApiClient();
  return async () => await client.searchmembers(shieldEnvironment());
}

function useSearchOrganisations() {
  const client = useApiClient();
  return async () => await client.searchorganisations(shieldEnvironment());
}

function useGetPersonByShieldContactId() {
  const client = useApiClient();

  return async (externalSystemId: string) =>
    await client.getpersonbyshieldcontactid(externalSystemId, shieldEnvironment());
}

function useGetOrganisationByShieldContactId() {
  const client = useApiClient();

  return async (externalSystemId: string) =>
    await client.getorganisationbyshieldcontactid(externalSystemId, shieldEnvironment());
}

function useSetMatchStatus() {
  const client = useApiClient();

  const onChange = async (contactId: string, statusCode: string) =>
    await client.setmembermatchresult(shieldEnvironment(), {
      ContactId: contactId,
      StatusCode: statusCode,
    });

  return { onChange };
}

function useIsForbiddenResponse(responseStatus: number | undefined) {
  return responseStatus === HTTP_STATUS_CODE_FORBIDDEN;
}

function useSetProductHoldingResponse() {
  const client = useApiClient();

  return async (statusCode: string) => {
    await client.setproductholdingresponse(shieldEnvironment(), {
      statusCode: statusCode,
    });
  };
}

function useGetProductHoldingResponse() {
  const client = useApiClient();

  return async (environment: string) => await client.getproductholdingresponse(environment);
}

function useCreatePerson() {
  const client = useApiClient();
  return async (newPerson: PersonRequest) => await client.createperson(shieldEnvironment(), newPerson);
}

function useCreateOrganisation() {
  const client = useApiClient();
  return async (newOrganisation: OrganisationRequest) =>
    await client.createorganisation(shieldEnvironment(), newOrganisation);
}

function useSetStatusOverride() {
  const client = useApiClient();
  return async (statusCode: string) => {
    await client.setstatusoverrideresponse(shieldEnvironment(), {
      statusCode: statusCode,
    });
  };
}

function useGetStatusOverrideResponse() {
  const client = useApiClient();

  return async (environment: string) => await client.getstatusoverrideresponse(environment);
}

function useUpdatePerson() {
  const client = useApiClient();
  return async (personId: string, updatedPerson: PersonRequest) =>
    await client.updateperson(personId, shieldEnvironment(), updatedPerson);
}

function useGetPersonProductHoldings() {
  const client = useApiClient();

  return async (externalSystemId: string) =>
    await client.getpersonproductholdings(externalSystemId, shieldEnvironment());
}

function useSetRoadsideAssistance() {
  const client = useApiClient();

  const onChange = async (contactId: string, roadsideAssistanceId: string | undefined) =>
    await client.setroadsideassistance(shieldEnvironment(), {
      ContactId: contactId,
      RoadsideAssistanceId: roadsideAssistanceId,
    });

  return { onChange };
}

export {
  useClearMembers,
  useSetIsAlive,
  useGetIsAlive,
  useSearchMembers,
  useSearchOrganisations,
  useGetPersonByShieldContactId,
  useGetOrganisationByShieldContactId,
  useSetMatchStatus,
  useIsForbiddenResponse,
  useSetProductHoldingResponse,
  useGetProductHoldingResponse,
  useCreatePerson,
  useCreateOrganisation,
  useUpdatePerson,
  useSetStatusOverride,
  useGetStatusOverrideResponse,
  useGetPersonProductHoldings,
  useSetRoadsideAssistance,
};
