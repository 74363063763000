import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { GridCellParams, GridRowModel } from "@mui/x-data-grid";
import { useRecoilState, useSetRecoilState } from "recoil";
import { dataGridRowsState, toastNotificationState } from "../../atoms";
import { backdropState } from "../../selectors";
import { ApiException, ApiResponse } from "../useApiClient/UtilityClientProxy.generated";
import { useSetRoadsideAssistance } from "../utilityApi";
import { contactIdField } from "./memberGridColumns";

interface SelectRoadsideAssistancePresentationProps {
  cell: GridCellParams;
  onChange: (contactId: string, roadsideAssistanceId: string | undefined) => Promise<ApiResponse<void>>;
}

export const SelectRoadsideAssistancePresentation: React.FC<SelectRoadsideAssistancePresentationProps> = ({
  cell,
  onChange,
}): JSX.Element => {
  const setNotificationState = useSetRecoilState(toastNotificationState);
  const setBackdrop = useSetRecoilState(backdropState);
  const [rows, setRows] = useRecoilState<GridRowModel[]>(dataGridRowsState);

  const contactId = cell.getValue(cell.id, contactIdField) as string;

  const updateRowsWithNewRoadsideAssistance = (newRoadsideAssistanceId: string) => {
    const newRows = rows.map((row) => {
      const newRow = {
        ...row,
      } as GridRowModel;

      if (newRow.id === contactId) {
        newRow.roadsideAssistanceId = parseInt(newRoadsideAssistanceId);
      }

      return newRow;
    });

    setRows(newRows);
  };

  const onSelectRoadsideAssistance = async (
    event: SelectChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    try {
      setBackdrop(true);

      const newRoadsideAssistanceId = event.target.value === "0" ? undefined : (event.target.value as string);

      await onChange(contactId, newRoadsideAssistanceId);
      updateRowsWithNewRoadsideAssistance(newRoadsideAssistanceId || "0");

      setNotificationState({
        message: `Roadside Assistance updated for contact #${contactId}`,
      });
    } catch (ex: any) {
      setNotificationState({
        message: `Unable to update Roadside Assistance for contact #${contactId}`,
        apiException: ex as ApiException,
      });
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <Select
      data-testid={`roadsideAssistance${contactId}`}
      value={cell.value || "0"}
      onChange={onSelectRoadsideAssistance}
      fullWidth
    >
      <MenuItem value="0">None</MenuItem>
      <MenuItem value="1">Classic RSA</MenuItem>
      <MenuItem value="2">Standard RSA</MenuItem>
      <MenuItem value="3">Ultimate RSA</MenuItem>
      <MenuItem value="4">Ultimate Plus RSA</MenuItem>
    </Select>
  );
};

const SelectRoadsideAssistance: React.FC<GridCellParams> = (cell) => {
  const { onChange } = useSetRoadsideAssistance();

  return <SelectRoadsideAssistancePresentation cell={cell} onChange={onChange} />;
};

export { SelectRoadsideAssistance };
