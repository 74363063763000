import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";

export interface LinkPersonButtonProps {
  onClick?: () => void;
}

const LinkPersonButton: React.FC<LinkPersonButtonProps> = ({ onClick }) => {
  const buttonName = "btn-link-person";
  return (
    <Button id={buttonName} title={buttonName} name={buttonName} startIcon={<Add />} size="small" onClick={onClick}>
      Link person
    </Button>
  );
};

export default LinkPersonButton;
