import { statusOverrideState } from "../../atoms";
import { SetDefaultResponse } from "../SetDefaultResponse";
import { retrieveStatusOverrideStatusCodes } from "../Settings/statusCodeSettings";
import { useSetStatusOverride } from "../utilityApi";

export const SetStatusOverride = () => {
  return (
    <SetDefaultResponse
      label="Create Status Override"
      name="statusOverrideSelector"
      title="Status Override status"
      statusCodeStateKey={statusOverrideState}
      setRemoteStatusCode={useSetStatusOverride()}
      items={retrieveStatusOverrideStatusCodes()}
    />
  );
};
export default SetStatusOverride;
