import { AZURE_AD_TOKEN_EXPIRED_MESSAGE, HTTP_STATUS_CODE_FORBIDDEN } from "../constants";
import { ToastNotificationProps } from "../ToastNotification";
import { ApiException, PersonProductHolding } from "../useApiClient/UtilityClientProxy.generated";
import { useGetPersonProductHoldings } from "../utilityApi";

export async function PopulateGrid(
  contactId: string | undefined,
  setRows: (state: PersonProductHolding[]) => void,
  setIsLoading: (state: boolean) => void,
  setNotificationState: (notificationState: ToastNotificationProps) => void,
) {
  const getPersonProductHoldings = useGetPersonProductHoldings();
  const clearGrid = () => {
    setRows([]);
  };

  try {
    setIsLoading(true);

    if (!contactId) {
      clearGrid();
      return null;
    }

    const response = await getPersonProductHoldings(contactId);
    setRows(response.result ?? []);
  } catch (error: any) {
    if (error.status === HTTP_STATUS_CODE_FORBIDDEN) {
      clearGrid();
      setNotificationState({
        message: AZURE_AD_TOKEN_EXPIRED_MESSAGE,
        apiException: error as ApiException,
      });
    }
  } finally {
    setIsLoading(false);
  }
}
