import { selector } from "recoil";
import { backdropCounterState } from "../atoms";
import { SESSION_KEY_PREFIX } from "../shared/constants";

const backdropKey = `${SESSION_KEY_PREFIX}backdrop`;

export const backdropState = selector<boolean>({
  key: backdropKey,
  get: ({ get }) => {
    return get(backdropCounterState) !== 0;
  },
  set: ({ set, get }, newValue) => {
    if (newValue) {
      set(backdropCounterState, get(backdropCounterState) + 1);
    } else {
      const currentBackdropCounterState = get(backdropCounterState);
      if (currentBackdropCounterState !== 0) {
        set(backdropCounterState, currentBackdropCounterState - 1);
      }
    }
  },
});
