import { MenuItem, Select } from "@mui/material";
import { RacwaSelectProps } from "@racwa/react-components";
import { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  environmentState,
  isAliveStatusCodeState,
  productHoldingStatusCodeState,
  statusOverrideState,
  toastNotificationState,
} from "../../atoms";
import { backdropState } from "../../selectors";
import { EnvironmentSettings } from "../Settings/environmentSettings";
import {
  retrieveIsAliveStatusCodes,
  retrieveProductHoldingStatusCodes,
  retrieveStatusOverrideStatusCodes,
} from "../Settings/statusCodeSettings";
import { ToastNotificationProps } from "../ToastNotification";
import { AZURE_AD_TOKEN_EXPIRED_MESSAGE, HTTP_STATUS_CODE_FORBIDDEN } from "../constants";
import { ApiException } from "../useApiClient/UtilityClientProxy.generated";
import { useGetIsAlive, useGetProductHoldingResponse, useGetStatusOverrideResponse } from "../utilityApi";

export interface SelectEnvironmentProps extends RacwaSelectProps {
  settings: EnvironmentSettings;
}

const isAliveStatusCodes = retrieveIsAliveStatusCodes().map((s) => s.code as number);
const productHoldingStatusCodes = retrieveProductHoldingStatusCodes().map((s) => s.code as number);
const statusOverrideStatusCodes = retrieveStatusOverrideStatusCodes().map((s) => s.code as number);

async function setCurrentStatusCode(
  setBackdrop: (showBackdrop: boolean) => void,
  setStatusCode: (statusCode: number) => void,
  setNotificationState: (notificationState: ToastNotificationProps) => void,
  getRemoteStatusCode: (environment: string) => Promise<any>,
  validStatusCodes: number[],
  environment: string,
  statusCodeName: string,
) {
  try {
    setBackdrop(true);

    const response = await getRemoteStatusCode(environment);

    if (validStatusCodes.includes(response.status)) {
      setStatusCode(response.status);
    }
  } catch (error: any) {
    if (validStatusCodes.includes(error.status)) {
      setStatusCode(error.status);
    } else {
      setNotificationState({
        message:
          error.status === HTTP_STATUS_CODE_FORBIDDEN
            ? AZURE_AD_TOKEN_EXPIRED_MESSAGE
            : `Request for mocked status code of '${statusCodeName}' for environment ${environment} failed.`,
        apiException: error as ApiException,
      });
    }
  } finally {
    setBackdrop(false);
  }
}

export const SelectEnvironment: React.FC<SelectEnvironmentProps> = ({ settings }) => {
  const [environment, setEnvironment] = useRecoilState(environmentState);
  const setBackdrop = useSetRecoilState(backdropState);
  const setNotificationState = useSetRecoilState(toastNotificationState);

  const setIsAliveStatusCode = useSetRecoilState(isAliveStatusCodeState);
  const getIsAlive = useGetIsAlive();

  const setProductHoldingResponseStatusCode = useSetRecoilState(productHoldingStatusCodeState);

  const setStatusOverride = useSetRecoilState(statusOverrideState);

  const getProductHolding = useGetProductHoldingResponse();
  const getStatusOverride = useGetStatusOverrideResponse();

  const onChange = async (e: any) => {
    const value = e.target.value;
    settings.setEnv(value);
    setEnvironment(value);
  };

  const optionElements = settings.retrieveAll().map((item: string, index: number) => {
    const optionProps = {
      key: index,
      value: item,
    };

    return <MenuItem {...optionProps}>{item}</MenuItem>;
  });

  useEffect(() => {
    setEnvironment(settings.current());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (environment) {
      setCurrentStatusCode(
        setBackdrop,
        setIsAliveStatusCode,
        setNotificationState,
        getIsAlive,
        isAliveStatusCodes,
        environment,
        "Environment Status",
      );

      setCurrentStatusCode(
        setBackdrop,
        setProductHoldingResponseStatusCode,
        setNotificationState,
        getProductHolding,
        productHoldingStatusCodes,
        environment,
        "RSA Bundling Response",
      );

      setCurrentStatusCode(
        setBackdrop,
        setStatusOverride,
        setNotificationState,
        getStatusOverride,
        statusOverrideStatusCodes,
        environment,
        "Create Status Override",
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environment]);

  return (
    <Select
      name="EnvironmentSelector"
      title="Select environment"
      onChange={onChange}
      value={environment}
      fullWidth={false}
    >
      {optionElements}
    </Select>
  );
};

export default SelectEnvironment;
