import { Delete } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";

export interface ClearEnvironmentButtonProps {
  isDisabled: boolean;
  onClick?: () => void;
}

const ClearEnvironmentButton: React.FC<ClearEnvironmentButtonProps> = ({ isDisabled, onClick }) => {
  const buttonName = "btn-clear";
  return (
    <Button
      id={buttonName}
      title={buttonName}
      name={buttonName}
      color="primary"
      onClick={onClick}
      disabled={isDisabled}
      size="small"
      startIcon={<Delete />}
      style={{ margin: "5px" }}
    >
      Clear
    </Button>
  );
};

export default ClearEnvironmentButton;
