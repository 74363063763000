import { backdropClasses, styled } from "@mui/material";
import { RacwaLoadingModal } from "@racwa/react-components";
import { useRecoilValue } from "recoil";
import { backdropState } from "../../selectors";

const backdropId = "backdrop";

const StyledLoadingModal = styled(RacwaLoadingModal)(({ theme }) => ({
  [`&.${backdropClasses.root}`]: {
    zIndex: theme.zIndex.drawer + 2,
  },
}));

const Backdrop: React.FC = () => {
  const isOpen = useRecoilValue(backdropState);
  return <StyledLoadingModal id={backdropId} data-testid={backdropId} open={isOpen} />;
};

export default Backdrop;
