import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, Collapse, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import CopyToClipboardButton from "../CopyToClipboardButton";
import { PersonProductHolding } from "../useApiClient/UtilityClientProxy.generated";
import { StyledTableRow } from "./styles";

export interface ProductHoldingRowProps {
  index: number;
  row: PersonProductHolding;
  open: boolean;
  toggle: (index: number) => void;
}

const ProductHoldingRow: React.FC<ProductHoldingRowProps> = ({ index, row, open, toggle }) => {
  const timestamp = row.timestamp ? new Date(row.timestamp + "Z").toLocaleString() : "";

  return (
    <>
      <StyledTableRow hover onClick={() => toggle(index)}>
        <TableCell>
          <IconButton size="small">{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {timestamp}
        </TableCell>
        <TableCell>{row.payload}</TableCell>
      </StyledTableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                <CopyToClipboardButton getText={() => row.payload ?? ""} />
                Payload
              </Typography>
              <Typography variant="caption" component="pre">
                <code>{row.payload}</code>
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ProductHoldingRow;
