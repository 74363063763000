import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import UseUpdateButton, { renderBlankHeader } from "../UpdateButton/updateButtonFunctions";
import { ReadOnlyColumn } from "./ReadOnlyColumn";
import { SelectMatchStatus } from "./SelectMatchStatus";
import { SelectRoadsideAssistance } from "./SelectRoadsideAssistance";
import { formatGridDate } from "./formatGridDate";
import { showProductHoldingsColumn } from "./showProductHoldingsColumn";

export const contactIdField = "id";
export const hasProductHoldingsField = "hasProductHoldings";

export const memberGridColumns = (
  updatePerson: (value: string) => {},
  showProductHoldings: (id: string) => void,
): GridColDef[] => {
  const dateOfBirthHeader = "Date of Birth";
  const productHoldingsHeader = "Product Holdings";
  const matchStatusHeader = "Match Status";
  return [
    {
      field: "Actions",
      hideSortIcons: true,
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          const id = params.getValue(params.id, contactIdField);
          updatePerson(id!.toString());
        };
        return UseUpdateButton(onClick);
      },
      renderHeader: renderBlankHeader,
    },
    ReadOnlyColumn("timestamp", "Timestamp", 0.75, true),
    ReadOnlyColumn(contactIdField, "Shield Contact Id", 0.5, false),
    ReadOnlyColumn("externalContactNumber", "Shield Ext#", 0.75, false),
    ReadOnlyColumn("crmId", "Mock Person ID", 1, true),
    ReadOnlyColumn("firstName", "First Name", 1, false),
    ReadOnlyColumn("surname", "Last Name", 1, false),
    ReadOnlyColumn("mobilePhoneNumber", "Mobile Number", 0.5, false),
    {
      field: "dateOfBirth",
      headerName: dateOfBirthHeader,
      description: dateOfBirthHeader,
      flex: 0.5,
      hideSortIcons: true,
      resizable: true,
      valueFormatter: (params) => formatGridDate(params),
    },
    ReadOnlyColumn("email", "Email", 1, false),
    ReadOnlyColumn("membershipTier", "Membership Tier", 0.5, true),
    {
      field: hasProductHoldingsField,
      headerName: productHoldingsHeader,
      description: productHoldingsHeader,
      flex: 1,
      hideSortIcons: true,
      editable: true,
      renderCell: (cellParams: GridCellParams) => showProductHoldingsColumn(cellParams, showProductHoldings),
    },
    {
      field: "roadsideAssistanceId",
      headerName: "Roadside Assistance",
      description: "Roadside Assistance product",
      flex: 0.75,
      hideSortIcons: true,
      editable: true,
      renderCell: (cellParams: GridCellParams) => SelectRoadsideAssistance(cellParams),
    },
    {
      field: "matchStatusCode",
      headerName: matchStatusHeader,
      description: matchStatusHeader,
      flex: 0.75,
      hideSortIcons: true,
      editable: true,
      renderCell: (cellParams: GridCellParams) => SelectMatchStatus(cellParams),
    },
  ];
};
