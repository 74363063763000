import { Button } from "@mui/material";
import React from "react";

export interface UpdateButtonProps {
  onClick?: () => void;
}

const UpdateButton: React.FC<UpdateButtonProps> = ({ onClick }) => {
  const buttonName = "btn-update-person";
  return (
    <Button id={buttonName} title={buttonName} name={buttonName} size="small" onClick={onClick}>
      Update
    </Button>
  );
};
export default UpdateButton;
