import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";

export interface LinkOrganisationButtonProps {
  onClick?: () => void;
}

const LinkOrganisationButton: React.FC<LinkOrganisationButtonProps> = ({ onClick }) => {
  const buttonName = "btn-link-organisation";
  return (
    <Button id={buttonName} title={buttonName} name={buttonName} startIcon={<Add />} size="small" onClick={onClick}>
      Link organisation
    </Button>
  );
};

export default LinkOrganisationButton;
