import { GridRowModel } from "@mui/x-data-grid";
import { AZURE_AD_TOKEN_EXPIRED_MESSAGE, HTTP_STATUS_CODE_FORBIDDEN } from "../constants";
import { ToastNotificationProps } from "../ToastNotification";
import { ApiException, MockOrganisation } from "../useApiClient/UtilityClientProxy.generated";
import { useSearchOrganisations } from "../utilityApi";

export async function PopulateGrid(
  setRows: (state: GridRowModel[]) => void,
  setIsLoading: (state: boolean) => void,
  setNotificationState: (notificationState: ToastNotificationProps) => void
) {
  const searchMembers = useSearchOrganisations();
  const clearGrid = () => {
    setRows([]);
  };

  try {
    setIsLoading(true);

    const response = await searchMembers();

    if (response.result === undefined || response.result.length === 0) {
      clearGrid();
    } else {
      const organisations = response.result;
      const rows = organisations.map((organisation: MockOrganisation) => {
        return { id: organisation.contactId, ...organisation };
      });

      setRows(rows);
    }
  } catch (error: any) {
    if (error.status === HTTP_STATUS_CODE_FORBIDDEN) {
      clearGrid();
      setNotificationState({
        message: AZURE_AD_TOKEN_EXPIRED_MESSAGE,
        apiException: error as ApiException,
      });
    }
  } finally {
    setIsLoading(false);
  }
}
